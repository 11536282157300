import DateRangePicker from 'daterangepicker/daterangepicker.js'
import moment from 'moment'

export default class DatePickers {
  constructor() {
    document.addEventListener('turbo:load', () => {
      if (document.body.matches('.transactions.index')) {
        this.transactionSearch()
      }
    })
  }

  transactionSearch() {
    // DateRangePicker https://www.daterangepicker.com/#options
    const dateRangeInput = document.querySelector('#transaction_search input[name="daterange"]')
    new DateRangePicker(dateRangeInput, {
      'opens': 'center', 
      'alwaysShowCalendars': true,
      'autoApply': true,
      'ranges': {
        'Today': [moment(), moment()],
        'Yesterday': [moment().subtract(1, 'day'), moment().subtract(1, 'day')],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'This Week': [moment().startOf('week'), moment().endOf('week')],
        'Last Week': [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
      },
    }, function(start, end) {
      document.getElementById('daterange').classList.remove('inactive')
      document.getElementById('q_transaction_date_gteq').value = start.format("YYYY-MM-DD")
      document.getElementById('q_transaction_date_lteq').value = end.format("YYYY-MM-DD")
    })

    if (document.getElementById('q_transaction_date_gteq').value !== '') {
      document.getElementById('daterange').classList.remove('inactive')
    }
  }
}
