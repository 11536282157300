export default class ClickableRowHandler {
  constructor() {
    document.addEventListener('turbo:load', () => {
      document.querySelectorAll('table tr[data-href]').forEach(row => {
        row.addEventListener('click', (e) => {
          Turbo.visit(e.target.parentElement.dataset.href)
        })
      })
    })
  }
}
